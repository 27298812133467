import { Controller } from "@hotwired/stimulus"
import { coloris, init } from "@melloware/coloris";

export default class extends Controller {
  initialize() {
    init();
  }

  connect() {
    const el = this.element

    coloris({
      el: `#${el.id}`,
      swatchesOnly: true,
      swatches: [
        '#0d6efd',    //blue
        '#6610f2',    //indigo
        '#6f42c1',    //purple
        '#d63384',    //pink
        '#dc3545',    //red
        '#fd7e14',    //orange
        '#73D4FF',    //light blue
        '#CC76FF',    //light indigo
        '#D5A8FF',    //light purple
        '#FF99EA',    //light pink
        '#FF9BAB',    //light red
        '#FFE47A',    //light orange
        '#ffc107',    //yellow
        '#198754',    //green
        '#20c997',    //teal
        '#0dcaf0',    //cyan
        '#fff',       //white
        '#000',       //black
        '#FFFF6D',    //light yellow
        '#7FEDBA',    //light green
        '#86FFFD',    //light teal
        '#73FFFF',    //light cyan
        '#dee2e6',    //light grey
        '#6c757d'     //dark grey
      ]
    });

    // Coloris button should adhere to tabindex of its parent
    el.previousElementSibling.tabIndex = el.tabIndex
  }
}
