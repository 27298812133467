import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['content', 'toggle', 'icon']

  connect() {
    this.hideAll()
  }

  toggle() {
    const contentIndex = this.toggleTargets.indexOf(event.target)
    this.contentTargets[contentIndex].classList.toggle('d-none')
    this.iconTargets[contentIndex].classList.toggle('fa-rotate-90')
  }

  hideAll() {
    this.contentTargets.forEach((content) => {
      content.classList.add('d-none')
    })

    this.iconTargets.forEach((icon) => {
      icon.classList.remove('fa-rotate-90')
    } )
  }
}
