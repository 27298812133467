import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "prevBtn", "nextBtn", "highlightCount"];
  static values = { active: Boolean }

  connect() {
    this.currentMark = 0;
    this.updateCount();
    this.inputTarget.focus();
  }

  // TODO: does nothing, can we make this work with turbo-permanent: true on <input>?
  teardown() {
    this.inputTarget.value = "";
  }

  get highlightableText() {
    return document.getElementById("highlightable_text");
  }

  get markElements() {
    if (!this.highlightableText) { return []; }

    return this.highlightableText.querySelectorAll("mark");
  }

  updateCount() {
    if (!this.hasHighlightCountTarget) { return; }

    this.highlightCountTarget.innerText = `${this.currentMark}/${this.markElements.length}`;
  }

  showHighlight() {
    let activeMark = this.markElements[this.currentMark - 1];

    this.markElements.forEach((mark) => {
      mark.classList.toggle("outlined", mark == activeMark);
    });

    activeMark?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
  }

  next() {
    if (this.currentMark < this.markElements.length) {
      this.currentMark++
      this.showHighlight()
      this.updateCount()
    }
  }

  previous() {
    if (this.currentMark > 0) {
      this.currentMark--
      this.showHighlight()
      this.updateCount()
    }
  }
}
