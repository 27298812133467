import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["checkbox", "actionBar", "selectionText", "selectedIds"]
  static values = { allIds: Array, key: String }

  // Lifecycle

  connect() {
    // When the page is loaded, check if there are any selected ids in the session
    // If there are, check the checkboxes that match the selected ids
    this.checkboxTargets.forEach(checkbox => {
      if (this.cachedIds.includes(checkbox.value)) {
        checkbox.checked = true
      }
    })

    // Whenever a checkbox is checked, or unchecked, update a session variable with the selected ids
    // This is used to keep track of the selected ids across pages
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('input', (e) => {
        this.toggleSelected(e)
      })
      checkbox.addEventListener('click', (e) => {
        if (e.shiftKey) this.addSelectedRange(e)
      })
    })

    this.refresh()
  }

  // Actions

  toggleSelected(e) {
    const checkbox = e.target
    if (checkbox.checked) {
      this.addToSelection(checkbox.value)
    } else {
      this.removeFromSelection(checkbox.value)
    }
    this.refresh()
  }

  addSelectedRange(e) {
    if (!e.shiftKey) return

    // get the index of the shift-clicked checkbox, then look back for the index of last checked checkbox
    const currentChecked = this.checkboxTargets.indexOf(e.target)
    const lastChecked = this.checkboxTargets.findLastIndex(checkbox => checkbox.checked && checkbox !== e.target)
    const start = Math.min(lastChecked, currentChecked)
    const end = Math.max(lastChecked, currentChecked)
    this.checkboxTargets.slice(start, end + 1).forEach(checkbox => {
      checkbox.checked = true
      this.addToSelection(checkbox.value)
    })

    this.refresh()
  }

  selectAllIds(e) {
    // add all ids to selected (will ignore those that are already selected)
    this.allIdsValue.forEach(id => {
      this.addToSelection(id)
    })

    // check all current checkboxes
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = true
    })

    this.refresh()
  }

  clearSelection() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
    })
    this.cachedIds = []
    this.refresh()
  }

  // Other functions

  addToSelection(id) {
    const selected = this.cachedIds
    if (!selected.includes(id)) {
      selected.push(id)
    }
    this.cachedIds = selected
  }

  removeFromSelection(id) {
    const selected = this.cachedIds
    if (selected.includes(id)) {
      selected.splice(selected.indexOf(id), 1)
    }
    this.cachedIds = selected
  }

  refresh() {
    this.toggleVisibility()
    this.updateSelectionText()
    this.updateFormInputs()
  }

  toggleVisibility() {
    this.actionBarTarget.classList.toggle('action-bar--selected', this.cachedIds.length > 0)
  }

  updateSelectionText() {
    this.selectionTextTarget.innerHTML = `${this.cachedIds.length} selected`
  }

  updateFormInputs() {
    this.selectedIdsTargets.forEach(input => input.value = JSON.stringify(this.cachedIds))
  }

  get cachedIds() {
    return JSON.parse(sessionStorage.getItem(`${this.keyValue}_currently_selected`)) || []
  }

  set cachedIds(ids) {
    sessionStorage.setItem(`${this.keyValue}_currently_selected`, JSON.stringify(ids))
  }
}
